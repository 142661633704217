import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const IntroPage = lazy(() => import('./routes/IntroPage'));

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
      () =>
      createTheme({
        root: {
          height: "100vh"
        },
        grid: {
          height: "100vh"
        },
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
      [prefersDarkMode],
  );

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/:orientation" element={<IntroPage/>} />
            <Route path="/" element={<IntroPage/>} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
